import React from 'react';
import PropTypes from 'prop-types';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import { getIn } from 'seamless-immutable';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { getImage } from '@catalogo/service-americanas-common/src/helpers/image';

class ZionImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesMap: ['extralarge', 'large', 'big', 'medium', 'small'],
    };
  }

  getSources() {
    const publication = getIn(this.props, ['publication']);
    const size = this.getSize();
    const ratio = this.getRatio(size);
    const imageOptions = getIn(publication, ['imageOptions']);
    const { width = 0, height = 0 } = getIn(this.props, ['publication', 'meta', size], {});
    return {
      desktop: {
        src: getImage(publication, this.getSize()) || '',
        ratio,
        alternateText: publication.alternateText,
        imageOptions,
        width,
        height,
      },
    };
  }

  getSize() {
    const { defaultSize = null } = getIn(this.props, ['publication']);
    const size = this.state.imagesMap.find(size => getIn(this.props, ['publication', size]) && size);

    if (size) {
      return size;
    }

    return defaultSize;
  }

  getRatio(size) {
    const { width = 0, height = 0 } = getIn(this.props, ['publication', 'meta', size], {});
    return `${width}:${height}`;
  }

  renderLink() {
    const { linkUrl, target } = getIn(this.props, ['publication']);

    return (
      <Nav to={linkUrl} target={target === 'Abrir em uma nova aba' ? '_blank' : '_self'}>
        {this.renderPicture()}
      </Nav>
    );
  }

  renderPicture() {
    const {
      publication: { alternateText, backgroundColor },
    } = this.props;

    return (
      <Wrapper className="spacey-image">
        <LazyPicture sources={this.getSources()} backgroundColor={backgroundColor} alt={alternateText} />
        {this.renderText()}
      </Wrapper>
    );
  }

  renderText() {
    const { alternateText, showTitle } = getIn(this.props, ['publication']);
    if (!showTitle) {
      return null;
    }
    return <Title>{alternateText}</Title>;
  }

  render() {
    const { linkUrl } = this.props.publication;

    if (linkUrl) {
      return this.renderLink();
    }
    return this.renderPicture();
  }
}

ZionImage.propTypes = {
  publication: PropTypes.object,
};

export default ZionImage;

const Wrapper = styled.div``;

const Title = styled.a`
  display: flex;
  font-size: 14px;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.fontColor || theme.titleColor};
  margin-top: 10px;

  &:hover {
    text-decoration: underline;
  }
`;
